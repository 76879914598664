/*
  Determines the difference between two dates by getting the difference between their milliseconds since the start of UTC,
  then dividing that number into minutes, hours, and finally days.

  Will return a negative number if the first date is before the second.
*/
export function getDaysBetweenDates(date1: Date, date2: Date): number {
  return Math.floor(
    Math.floor((date1.getTime() - date2.getTime()) / 1000) / 60 / 60 / 24
  );
}
